import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import api_core from '../../services/config.js';
import { Box, Stack, Typography } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton/index.js";
import Operacion from "Repository/Operacion.js";

function CargarCreditos(params) {

    const [response, setResponse] = useState({ message: null, status: null });

    const operacion = new Operacion();

    const onDrop = useCallback(async (acceptedFile) => {

        setResponse({ message: null, status: null });

        const file = acceptedFile[0]; if (!file) return;

        const formData = new FormData();
        formData.append('csv_file', file);

        try {
            const isvalid = await operacion.isValidToken();
         
            const renew = await operacion.refreshToken(isvalid.status);

            await api_core.post('panel/creditos/cargar',
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Authorization': `Bearer ${localStorage.getItem('cToken')}`
                    }
                }
            )

            setResponse({ message: 'Se ha realizado la operacion correctamente', status: 200 })
            
        } catch (error) {

            if (error.response.status === 400) {

                setResponse({ message: error.response.data.response.message, status: 400 })
            } else {

                setResponse({ message: error.response.data.response.message, status: 500 })
            }
        }
    });

    async function downloadXlsx() {

        try {
            const isvalid = await operacion.isValidToken();
         
            const renew = await operacion.refreshToken(isvalid.status);

            const response = await api_core.get('panel/creditos/template', {
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('cToken')}`
                }
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'carga_de_creditos_template.xlsx'); 

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

        } catch (error) {
            setResponse({ message: "Hubo un problema", status: 500 })
        }
    }

    const { getRootProps, getInputProps, isDragActive, acceptedFiles, } = useDropzone({
        onDrop,
        accept: {
            'text/*': ['.csv', '.xlsx']
        }
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <Stack
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                minHeight="70vh"
            >

                <Box

                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "2rem",
                        padding: "2rem",
                        border: "1px solid rgb(232, 232, 232)",
                        backgroundColor: '#fff',
                        borderRadius: '8px',
                        boxShadow: 3,
                        width: {
                            xs: '100%',
                            sm: '100%',
                            md: '50%',
                            lg: '50%',
                            xl: '50%',
                        }
                    }}
                >
                    <Box
                        {...getRootProps()}
                        sx={{
                            border: '2px dashed #1976d2',
                            padding: 2,
                            textAlign: 'center',
                            cursor: 'pointer',
                            width: '100%',
                            height: "100%",
                            bgcolor: '#F5F5F5',
                        }}
                    >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <p>Suelta el excel aquí...</p>
                        ) : (
                            <p>Arrastra y suelta el excel, o haz clic para seleccionarlo</p>
                        )}
                    </Box>

                    <SoftTypography>
                        Archivo: {acceptedFiles?.[0]?.name}
                    </SoftTypography>

                    {(response.message && response.status) && (

                        <Typography color={response.status == 200 ? "green" : "red"}>
                            * {response.message}
                        </Typography>
                    )}

                    <SoftButton
                        color="color6A"
                        size="small"
                        sx={{
                            width: {
                                xs: "100",
                                sm: "50%",
                                md: "50%",
                                xl: "50%",
                                xxl: "30%"
                            }
                        }}
                        onClick={downloadXlsx}
                    >
                        Descargar plantilla
                    </SoftButton>

                </Box>
            </Stack>
        </DashboardLayout>
    );

}

export default CargarCreditos;