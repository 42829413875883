/**
 =========================================================
 * Soft UI Dashboard React - v4.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-routers components

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { v4 as uuidv4 } from "uuid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard React base styles
import CentroDeCosto from "../../../Repository/CentroDeCosto";
import {useDispatch, useSelector} from "react-redux";
import {descargaArchivo, verEstadocuenta, verEstadocuentaPersonalizado} from "../../../store/thunk/estadocuenta";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import SoftInput from "../../../components/SoftInput";
import SoftButton from "../../../components/SoftButton";
import {useForm} from "../../../customHooks/useForm";
import {useState} from "react";
import "flatpickr/dist/themes/dark.css";
import Flatpickr   from 'react-flatpickr';
import {useDateFormat} from "../../../customHooks/useDateFormat";
import moment from "moment";
import Swal from "sweetalert2";
import Checkbox from "@mui/material/Checkbox";
import {useAlert} from "../../../customHooks/useAlert";
import { Stack } from "@mui/material";
import { showCreditosEmpresa, estadoCuentaCancelWiewing } from "../../../store/slice/estadoCuentaSlice";

moment.locale("es-MX");
const initialForm = {
    fecha_inicial: "",
    fecha_final:"",
    chkSoloDepositos: false,
    chkSoloRetiros: false,
}

const formValidation = {
    fecha_inicial: [(value)=> value.trim() == "","Fecha inicial requerida."],
    fecha_final: [(value)=>{ return value.trim() == ""; } ,"Fecha final requerida."],
    chkSoloDepositos: [()=>{ return false; } ,""],
    chkSoloRetiros: [()=>{ return false; } ,""]
}

function CuentaInfoCard({ cdc_uuid,cuenta = "", info = [], centros = [], operaciones = {} }) {
    const { downloading } = useSelector(state=> state.estadocuenta);

    const format = "DD-MM-YYYY";
    const { fecha_inicial, fecha_final,chkSoloDepositos,chkSoloRetiros, onInputChange, isFormValid } = useForm( initialForm, formValidation );
    const { localeFormat } = useDateFormat();
    const { showAlert } = useAlert();
    const [ tmp, setTmp ] = useState();
    const dispatch = useDispatch();
    const centrox = new CentroDeCosto();

    const onClickEstado = (periodo)=>{
        const params = obtenerParametros(periodo);
        //showAlert("Notificaciones", "Estamos generando el documento, espere un momento por favor");
        dispatch( descargaArchivo({ 'cdc_uuid':cdc_uuid, 'params':params, 'accept':"application/pdf" }) );
    }

    const onClickVerEstado = (periodo) =>{
        const params = obtenerParametros(periodo);
        dispatch( verEstadocuentaPersonalizado(cdc_uuid, params) )
    }

    const onClickExcelEstado = (periodo) => {
        const params = obtenerParametros(periodo);
        dispatch( descargaArchivo({ 'cdc_uuid':cdc_uuid, 'params':params, 'accept':"application/vnd.ms-excel" }) );
    }

    const obtenerParametros = ( periodo) =>{
        const fecha1 = moment(`${periodo}-01`,'YYYY-MM-DD');
        const fecha2 = (fecha1.clone().endOf("month")).format('YYYY-MM-DD');
        const params = {
            "fecha_inicial":fecha1.format('YYYY-MM-DD'),
            "fecha_final":fecha2,
            "chkSoloRetiros": false,
            "chkSoloDepositos": false,
        }
        return params;
    }

    const onClick_filtro = ()=>{
        const fechaIni = moment(fecha_inicial,format);
        const fechaFin = moment(fecha_final, format);
        const fechasValidas = fechaIni.isValid() && fechaFin.isValid();
        if(fechasValidas){
            const diffInDays = fechaFin.diff(fechaIni,'days');
            if(diffInDays >=0 && diffInDays < 61){
                const params =  {
                    "fecha_inicial" :fechaIni.format('YYYY-MM-DD'),
                    "fecha_final"   :fechaFin.format('YYYY-MM-DD'),
                    "chkSoloRetiros": chkSoloRetiros,
                    "chkSoloDepositos": chkSoloDepositos,
                }
                dispatch( verEstadocuentaPersonalizado(cdc_uuid, params) )
            }else{
                showMessage(`${ diffInDays < 0 ? "Periodo inválido":"El periodo especificado no debe exceder los 60 días naturales" }`);
                return;
            }
        }else{
            showMessage("Rango de fechas inválido");
            return;
        }
        
        dispatch( showCreditosEmpresa(null) );

        //centrox.estadoDeCuentaDataPersonalizado(cdc_uuid, fechaInicial, fechaFinal);
        //console.log("Filtro he", fechaInicial, fechaFinal, isFormValid);
    }

    const showCredits = () => {
        dispatch( estadoCuentaCancelWiewing() );
        dispatch(showCreditosEmpresa(operaciones.empresa_uuid))
    }

    const showMessage  = (msg, title="Notificaciones",type= "success")=>{
        Swal.fire({
            title: title,
            text: msg,
            icon: type,
            showCancelButton: false,
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        });
    }

    //#region
    const download =  async (periodo)=>{
        centrox.getEstadoDeCuenta(cdc_uuid,periodo);
    }
    //#endregion

    // Render the card info items
    const renderItems = centros.map((centro) => (

        <Grid key={ uuidv4() } container>
            <Grid item xs={12} md={12} xl={6}>
                <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    {centro.nombre_periodo}: &nbsp;
                </SoftTypography>
            </Grid>
            <Grid item xs={12} md={12} xl={6} textAlign={"right"}>

                <IconButton variant={ "text" } color={"dark"} title={"Consultar en Línea"}
                            sx={{ fontSize:18 }}
                            onClick={ ()=> onClickVerEstado(centro.periodo) }
                >
                    <i className="fa-solid fa-list"></i>
                </IconButton>
                <IconButton variant={ "text" } color={"dark"} title={"Exportar a Excel"} disabled={ downloading }
                            sx={{ fontSize:18 }}
                            onClick={ ()=> onClickExcelEstado(centro.periodo) }
                >
                    <i   className="fa-solid fa-file-excel"></i>
                </IconButton>
                <IconButton  variant={ "text" } color={"dark"} title={"Exportar como PDF"} disabled={ downloading }
                            sx={{ fontSize:18 }}
                            onClick={ ()=> onClickEstado(centro.periodo) }
                >
                    <i className="fa-regular fa-file-pdf"></i>
                </IconButton>
            </Grid>
        </Grid>

    ));

    const renderLabel = info.map((field) => (
        <SoftBox key={ uuidv4() } display="flex" py={1} pr={2} sx={{ paddingTop:0, paddingBottom:0 }}>
            <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
                {field.label}: &nbsp;
            </SoftTypography>
            <SoftBox >

            </SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="text" >
                {field.value}
            </SoftTypography>
        </SoftBox>
    ));

    return (
        <Card sx={{ marginBottom:1 }} >
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2} >
                <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize" >
                    {cuenta}
                </SoftTypography>
            </SoftBox>
            <SoftBox pl={2} pt={1}>
                <SoftBox mb={1} lineHeight={1}>
                    {renderLabel}
                </SoftBox>
                <SoftBox sx={{ borderColor:"#123AAA", borderTop:2, borderBottom:2, textAlign:"center",marginTop:1, marginBottom:1 }}>
                    <SoftTypography sx={{ fontSize:12 }} >
                        Estado de cuenta personalizado
                    </SoftTypography>
                </SoftBox>
                <SoftBox  lineHeight={1}>
                    <Grid container >
                        <Grid item xs={12} md={12} xl={6} pl={1} pr={1}>
                            <Flatpickr
                                value={fecha_inicial}
                                options ={{ dateFormat:"d-m-Y", altFormat:"d-m-Y", locale:  localeFormat    }}
                                className={"customPicker"}
                                placeholder={"Desde"}
                                onChange={(selectedDates, dateStr, instance) => {
                                    const customEvent =  {
                                        target:{
                                            name : "fecha_inicial",
                                            value: dateStr
                                        }
                                    }
                                    onInputChange(customEvent);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} xl={6} pl={1} pr={1}>
                            <Flatpickr
                                value={fecha_final}
                                options ={{ dateFormat:"d-m-Y", altFormat:"d/m/Y", locale:  localeFormat    }}
                                className={"customPicker"}
                                placeholder={"Hasta"}
                                onChange={(selectedDates, dateStr, instance) => {
                                    const customEvent =  {
                                        target:{
                                            name : "fecha_final",
                                            value: dateStr
                                        }
                                    }
                                    onInputChange(customEvent);
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} xl={6} pl={1} pr={1} pt={1}>
                            <SoftBox display="flex" alignItems="center">
                                <Checkbox name="chkSoloDepositos" checked={ chkSoloDepositos }
                                          onChange={(event) => onInputChange( {target:{ name: event.target.name, value: !chkSoloDepositos }}  )} />
                                <SoftTypography
                                    variant="button"
                                    fontWeight="regular"
                                    sx={{ cursor: "poiner", userSelect: "none" }}
                                    onClick={(event) => onInputChange( {target:{ name: 'chkSoloDepositos', value: !chkSoloDepositos }}  )}
                                >
                                    Depósitos
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={6} xl={6} pl={1} pr={1} pt={1}>
                            <SoftBox display="flex" alignItems="center">
                                <Checkbox name="chkSoloRetiros" checked={ chkSoloRetiros } onChange={(event) => onInputChange( {target:{ name: event.target.name, value: !chkSoloRetiros }}  )} />
                                <SoftTypography
                                    variant="button"
                                    fontWeight="regular"
                                    sx={{ cursor: "poiner", userSelect: "none" }}
                                    onClick={(event) => onInputChange( {target:{ name: 'chkSoloRetiros', value: !chkSoloRetiros }}  )}
                                >
                                    Retiros
                                </SoftTypography>
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12} md={12} xl={12} p={1}>
                            <SoftButton key={ uuidv4() } color="color6A" size="small" fullWidth
                            onClick={ onClick_filtro } disabled={ isFormValid }
                            >Filtrar</SoftButton>
                        </Grid>
                    </Grid>

                </SoftBox>
                <SoftBox sx={{ borderColor:"#123AAA", borderTop:2, borderBottom:2, textAlign:"center",marginTop:1 }}>
                    <SoftTypography sx={{ fontSize:12 }} >
                        Estados de cuenta
                    </SoftTypography>
                </SoftBox>
                <SoftBox >
                    {renderItems}
                </SoftBox>

                <SoftBox sx={{ borderColor:"#123AAA", borderTop:2, borderBottom:2, textAlign:"center",marginTop:1 }}>

                    <SoftTypography sx={{ fontSize:12 }} >
                        Operaciones
                    </SoftTypography>
                    
                </SoftBox>
                <Stack marginTop={1}>
                    <SoftTypography sx={{ fontSize:13 }}>
                        <b>Saldo de transacciones:</b> {operaciones.restantes}
                    </SoftTypography>
                </Stack>
                
                <Grid item xs={12} md={12} xl={12} p={1}>
                        <SoftButton key={ uuidv4() } color="color6A" size="small" fullWidth
                        onClick={ (showCredits) }>
                            Ver historial
                        </SoftButton>
                </Grid>

            </SoftBox>
        </Card>
    );
}

// Typechecking props for the ProfileInfoCard
CuentaInfoCard.propTypes = {
    cdc_uuid: PropTypes.string.isRequired,
    cuenta: PropTypes.string.isRequired,
    centros: PropTypes.array.isRequired,
    info: PropTypes.array.isRequired,
    operaciones: PropTypes.object.isRequired,
};

export default CuentaInfoCard;