import { useState, useRef, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Flatpickr from 'react-flatpickr';
import Box from '@mui/material/Box';
import moment from 'moment';
import api_core from '../../../services/config.js';
import PropTypes from "prop-types";
import { esES } from '@mui/x-data-grid/locales';
import { Button } from '@mui/material';
import SoftTypography from 'components/SoftTypography/index.js';
import Operacion from 'Repository/Operacion.js';

moment.locale('es-MX');

const ListadoCreditos = ({ empresaUuid }) => {

    const [dates, setDates] = useState({ startDate: '', endDate: '' });
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 15 });

    const mapPageToNextCursor = useRef({});
    const [hasNextPage, setHasNextPage] = useState(true);

    const rowCount = useRef(0);
    const isCountRows = useRef(true);

    const operacion = new Operacion();

    const fetchData = useCallback(async (page, pageSize) => {
        setLoading(true);
        try {
            let url = `panel/creditos/consultar?empresa_uuid=${empresaUuid}&limit=${pageSize}`;

            if (page > 0 && mapPageToNextCursor.current[page - 1]) {
                url += `&cursor=${encodeURIComponent(mapPageToNextCursor.current[page - 1])}`;
            }

            if (dates.startDate) {
                const formattedStartDate = moment(dates.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00');
                url += `&start_date=${encodeURIComponent(formattedStartDate)}`;
            }

            if (dates.endDate) {
                const formattedEndDate = moment(dates.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD 23:59:59');
                url += `&end_date=${encodeURIComponent(formattedEndDate)}`;
            }

            const isvalid = await operacion.isValidToken();

            const renew = await operacion.refreshToken(isvalid.status);

            const response = await api_core.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('cToken')}`
                }
            });

            const data = await response.data;

            setRows(data.rows);
            setHasNextPage(data.hasNextPage);

            if (data.rows.length > 0 && isCountRows.current) {
                rowCount.current += data.rows.length;
            }

            if (!data.hasNextPage) {
                isCountRows.current = false;
            }

            if (data.cursor) {
                mapPageToNextCursor.current[page] = data.cursor;
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, [empresaUuid, dates, api_core]);


    const prevFilters = useRef({ empresaUuid, dates });

    useEffect(() => {
        const filtersChanged =
            prevFilters.current.empresaUuid !== empresaUuid ||
            prevFilters.current.dates !== dates;

        if (filtersChanged) {

            mapPageToNextCursor.current = {};
            rowCount.current = 0;
            isCountRows.current = true;

            if (paginationModel.page !== 0) {
                setPaginationModel(prev => ({ ...prev, page: 0 }));

                prevFilters.current = { empresaUuid, dates };
                return;
            }

            prevFilters.current = { empresaUuid, dates };
        }

        fetchData(paginationModel.page, paginationModel.pageSize);
    }, [empresaUuid, dates, paginationModel.page, paginationModel.pageSize, fetchData]);



    const handlePageChange = (newPage) => {
        if (newPage > paginationModel.page && !hasNextPage) return;
        setPaginationModel((prev) => ({ ...prev, page: newPage }));
    };

    const handleDateChange = (name, dateStr) => {
        setDates((prev) => ({ ...prev, [name]: dateStr }));
    };

    const columns = [
        { field: 'uuid', headerName: 'uuid' },
        {
            field: 'fecha',
            headerName: 'Fecha',
            headerAlign: 'center',
            align: "center",
            flex: 1,
            minWidth: 150,
            maxWidth: 350,
        },
        {
            field: 'operaciones',
            headerName: 'Operaciones',
            headerAlign: 'center',
            align: "center",
            flex: 1,
            minWidth: 150,
            maxWidth: 400,
        },
        {
            field: 'acumulado',
            headerName: 'Acumulado',
            headerAlign: 'center',
            align: "center",
            flex: 1,
            minWidth: 150,
            maxWidth: 400,
        },
    ];

    return (
        <Box sx={{ height: 700, width: '100%' }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: {
                        xs: 'column',
                        sm: 'row',
                        md: 'row',
                        lg: 'row',
                        xl: 'row',
                    },
                    gap: 2,
                    alignItems: 'center',
                    p: 2,
                    borderRadius: 1,
                    boxShadow: 1,
                    mb: 2,
                    mt: {
                        xs: 1,
                        sm: 1,
                        md: 0,
                    },
                    bgcolor: "#FFFFFF",
                }}
            >
                <SoftTypography>Filtros:</SoftTypography>
                <Box sx={{
                    display: 'flex',
                    gap: '1rem',
                    width: {
                        xs: '100%',
                        sm: '100%',
                        md: '100%',
                        xl: '40%',

                    }
                }}>
                    <Flatpickr
                        value={dates.startDate}
                        options={{ dateFormat: 'd-m-Y' }}
                        className="customPicker"
                        placeholder="Desde"
                        onChange={(_, dateStr) => handleDateChange('startDate', dateStr)}

                    />
                    <Flatpickr
                        value={dates.endDate}
                        options={{ dateFormat: 'd-m-Y' }}
                        className="customPicker"
                        placeholder="Hasta"
                        onChange={(_, dateStr) => handleDateChange('endDate', dateStr)}
                    />
                </Box>
                <Button
                    variant="none"
                    onClick={() => {
                        handleDateChange('startDate', '');
                        handleDateChange('endDate', '');
                    }}
                    sx={{
                        backgroundColor: "#4A80A8",
                        color: "#FFFFFF !important",
                        '&:hover': {
                            backgroundColor: "#4A80A8 !important"
                        }
                    }}
                    disabled={!dates.startDate && !dates.endDate}
                >
                    Limpiar
                </Button>
            </Box>
            <DataGrid
                cellClassName
                rows={rows}
                columns={columns}
                paginationMode="server"
                sortingMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={(model) => handlePageChange(model.page)}
                pageSizeOptions={[15]}
                rowCount={isCountRows.current ? -1 : rowCount.current}
                loading={loading}
                getRowId={(row) => row.uuid}
                columnVisibilityModel={{
                    uuid: false,
                }}
                disableColumnMenu={true}
                disableColumnSorting={true}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                sx={{
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer',
                        color: '#FFFFFF',
                        backgroundColor: '#5F9BC4',
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none !important'
                    },
                    '& .MuiDataGrid-row:nth-of-type(even)': {
                        backgroundColor: '#FFFFFF',
                        '&:hover': {
                            backgroundColor: '#5F9BC4'
                        }
                    },
                    '& .MuiDataGrid-row:nth-of-type(odd)': {
                        backgroundColor: '#DCF1FF',
                        '&:hover': {
                            backgroundColor: '#5F9BC4'
                        }
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderTopRightRadius: '0.7rem',
                        borderTopLeftRadius: '0.7rem',
                    },
                    '& .MuiDataGrid-columnHeader': {
                        backgroundColor: '#999999',
                        color: '#F2F2F2',
                        margin: '0 0.1rem 0 0',
                        height: '3rem !important',
                        borderTopLeftRadius: '0.7rem',
                        borderTopRightRadius: '0.7rem',
                    },
                    '& .MuiDataGrid-columnHeader:focus': {
                        outline: 'none !important'
                    },
                    '& .MuiCircularProgress-root': {
                        color: '#4A80A8'
                    }
                }}
            />
        </Box>
    );
};

ListadoCreditos.propTypes = {
    empresaUuid: PropTypes.string.isRequired,
};

export default ListadoCreditos;
