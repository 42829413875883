/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// @mui material components
import {useDispatch, useSelector} from "react-redux";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Soft UI Dashboard React base styles

// Data
import { CentrosDeCosto } from "./components/CentrosDeCosto";
import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {EstadoDeCuenta} from "./components/EstadoCuenta/EstadoDeCuenta";
import {estadoCuentaCancelWiewing, isRefreshing, saveLoading, showCreditosEmpresa} from "../../store/slice/estadoCuentaSlice";
import {setMiniSidenav} from "../../context";
import {useWindowHeight} from "../../customHooks/useWindowHeight";
import ListadoCreditos from "./components/ListadoCreditos";

function Dashboard() {

    const dispatch = useDispatch();
    const { windowHeight } = useSelector( state => state.ventana );
    const { cdc_uuid, loading, refreshing, empresa_uuid } = useSelector( state => state.estadocuenta );

    //limpiamos el estado al ingesar a la pagina
    useEffect(()=>{
        dispatch( estadoCuentaCancelWiewing() );
        dispatch( showCreditosEmpresa(null) );
    },[]);
  //Si ya esta autenticado devolvemos el dashboard si no lo mandamos a loguearse


    return (
      <DashboardLayout sx={{ overflow:"hidden" }}>
        <DashboardNavbar />
        <SoftBox >

            <Grid container>
                <Grid item xs={12} md={3} xl={3} pt={1}
                >
                    <SoftBox className={"edo-cuenta"} sx={{ height: windowHeight +"px",position:"relative", overflow:"auto", minHeight: windowHeight+"px" }}   >
                        <CentrosDeCosto />
                    </SoftBox>
                </Grid>

                <Grid item xs={12} md={9} xl={9} >
                    <SoftBox>
                        { cdc_uuid && (!loading || !refreshing) ? <EstadoDeCuenta /> : '' }
                    </SoftBox>
                    <SoftBox ml={1}mr={1}>
                        {empresa_uuid && <ListadoCreditos empresaUuid={ empresa_uuid }/>}
                    </SoftBox>
                </Grid>

            </Grid>


          
        </SoftBox>
      </DashboardLayout>
    );
  
}

export default Dashboard;
